import { createRoot } from 'react-dom/client';
import { HorizontalScroll, router } from './horizontalscroll';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './index.css';

if (document.getElementById('reactcontainer')) {
    console.log('found el')
    createRoot(document.getElementById('reactcontainer')).render(<RouterProvider router={router} />);
} else {
    console.log('no el')
}