import { Header } from "./header"
import { getBg, urlBase, getBgAttachment, getBgSize } from './constants';

export const HomePage = ({ theme, showSubtext, contentData, headerLinkClick, setTheme, globalSw }) => {

    return (
        <div
            id="page1"
            style={{ backgroundImage: getBg(1, theme, globalSw), backgroundRepeat: 'no-repeat', backgroundSize: getBgSize(1, theme, globalSw), backgroundAttachment: getBgAttachment(1, theme, globalSw) }}
            className=" flex-col z-0  flex  lg:h-screen w-screen pb-20 lg:py-0 "
        >
            <div className="flex flex-col max-w-screen-2xl px-8 h-full">
                <div className=" flex w-full h-auto lg:h-60 mb-8 lg:mb-0 mt-8 lg:grid grid-cols-1 ">
                    <div
                        className="flex lg:hidden "

                    >
                        <img alt={'DaschleLogo.png'} className="h-auto lg:h-12" src={theme === "dark" ? `${urlBase.replace('/06', '/07')}Daschle-WhiteLogo.png` : `${urlBase.replace('/06', '/07')}Daschle-ColorLogo.png`} />
                    </div>
                    <div className="hidden lg:block">
                        <Header setTheme={setTheme} headerLinkClick={headerLinkClick} />

                    </div>
                    <div className="hidden lg:block ">
                        {/* <Header setTheme={setTheme} headerLinkClick={headerLinkClick} /> */}
                    </div>
                </div>
                <div className={"mt-8 lg:mt-0 flex  grid-cols2-40 w-full h-full " + (theme === 'cherry' ? ' lg:flex' : 'lg:grid')}>
                    <div className="flex flex-col"></div>
                    <div className={"flex flex-col " + ((theme == 'cherry' && globalSw > 1024) ? 'mr-custom-1' : '')}>
                        <div className=" text-3xl lg:text-5xl xl:text-5xl mb-6  ">
                            {contentData.homepage_title}
                        </div>
                        {showSubtext &&
                            <div className="flex overflow-hidden slidein">

                                <div className=" flex flex-col text-custom1 lg:font-18">
                                    {contentData.homepage_text}
                                </div>

                            </div>
                        }
                        <div className=""></div>
                    </div>
                </div>



            </div>
            <div className="flex lg:grid grid-cols2-40 w-full   mb-12 lg:mb-10 items-end ">
                <div
                    className="hidden lg:block  ml-10"

                >
                    {/* <img className="h-auto lg:h-12" src={theme === "dark" ? `${urlBase.replace('/06', '/07')}Daschle-WhiteLogo.png` : `${urlBase.replace('/06', '/07')}Daschle-ColorLogo.png`} /> */}
                    <img className="h-auto lg:h-12" alt="daschle logo" src={theme === "dark" ? contentData?.logo_dark : contentData?.logo_light} />
                </div>
                <div className="flex w-full">
                    <div className="ml-auto mr-14 hidden lg:flex text-2xl items-center hover:text-amber-400 cursor-pointer" onClick={() => headerLinkClick(1)}>
                        <div className="font-18">Click to Navigate</div> <div className="text-custom3 relative  bottom-0 hover:font-bold">&#10230;</div>
                    </div>
                </div>

            </div>

        </div>
    )
}